<template>
  <div class="operator-details-wrapper" v-if="operatorData">
    <div class="operator-details-layout" >
      <!--Hébergement-->
        <b-card class="m-0 operator-card" v-if="operatorData.secteur_id === 1">
        <h3 class="mb-1">{{ $t('sectors.hebergement') }} {{ operatorData.nom_etablissement && operatorData.nom_etablissement }}</h3>
        <div class="operator-images-container" v-if="operatorData.media">
          <div
            v-for="(media, index) in operatorData.media"
            :key="index"
            class="operator-image-wrapper"
            @click="showImgModal(media.original_url, media.name)"
          >
            <img
              :src="media.original_url"
              :alt="media.name"
              class="operator-image"
            />
          </div>
        </div>

        <!--<p>
          <strong>{{ $t('operator_manage.detail_label_ifu') }} : </strong>
          {{ operatorData.ifu }}
        </p>
        <p><strong>RCCM : </strong> {{ operatorData.rccm }}</p>-->
        <p >
          <strong>{{ $t('table_columns.company_name') }} : </strong>
          {{ operatorData.nom_etablissement && operatorData.nom_etablissement }}        
        </p>
        <p>
          <strong>{{ $t('table_columns.sector') }} : </strong>
          {{ operatorData.secteur.title && operatorData.secteur.title }}
        </p>
        <p>
          <strong>{{ $t('table_columns.type_etablissement') }} : </strong>
          {{ operatorData.secteur.type_etablissement && operatorData.secteur.type_etablissement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.ranking') }} : </strong>
          {{ operatorData.secteur.classement && operatorData.secteur.classement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.address') }} : </strong>
          {{ operatorData.adresse_etablissement && operatorData.adresse_etablissement }}
        </p>
        <p >
          <strong>{{ $t('table_columns.phone') }} : </strong>
          {{ operatorData.telephone_etablissement && operatorData.telephone_etablissement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.email') }} : </strong>
          {{  operatorData.email_etablissement && operatorData.email_etablissement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.web_site') }} : </strong>
          {{ operatorData.web_site && operatorData.web_site }}
        </p>
        <p>
          <strong>{{ $t('table_columns.departement') }} : </strong>
          {{ operatorData.departement && operatorData.departement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.commune') }} : </strong>
          {{ operatorData.commune && operatorData.commune }}
        </p>
        <p>
          <strong>{{ $t('table_columns.district') }} : </strong>
          {{ operatorData.arrondissement && operatorData.arrondissement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.village') }} : </strong>
          {{ operatorData.quartier && operatorData.quartier}}
        </p>
        <strong style="margin-bottom: 10px; display: inline-block"
          >{{ $t('operator_manage.detail_label_location') }} :
        </strong>
        <div class="map-wrapper" v-if="operatorData.localization && operatorData.localization.lat">
          <MapsComponent :positions="[operatorData.localization.lat,operatorData.localization.lng]" />
        </div>
        
      </b-card>
       <!--Voyage-->
      <b-card class="m-0 operator-card" v-else-if="operatorData.secteur_id === 2">
        <h3 class="mb-1">{{ $t('sectors.agency_travel') }} {{ operatorData.nom_etablissement && operatorData.nom_etablissement }}</h3>
        <div class="operator-images-container" v-if="operatorData.media">
          <div
            v-for="(media, index) in operatorData.media"
            :key="index"
            class="operator-image-wrapper"
            @click="showImgModal(media.original_url, media.name)"
          >
            <img
              :src="media.original_url"
              :alt="media.name"
              class="operator-image"
            />
          </div>
        </div>

        <!--<p>
          <strong>{{ $t('operator_manage.detail_label_ifu') }} : </strong>
          {{ operatorData.ifu }}
        </p>
        <p><strong>RCCM : </strong> {{ operatorData.rccm }}</p>-->
        <p>
          <strong>{{ $t('table_columns.sector') }} : </strong>
          {{ operatorData.secteur.title && operatorData.secteur.title }}
        </p>
        <p >
          <strong>{{ $t('table_columns.company_name') }} : </strong>
          {{ operatorData.nom_etablissement && operatorData.nom_etablissement }}        
        </p>
        <p>
          <strong>{{ $t('table_columns.numero_agrement') }} : </strong>
          {{ operatorData.numero_agrement && operatorData.numero_agrement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.address') }} : </strong>
          {{ operatorData.adresse_etablissement && operatorData.adresse_etablissement }}
        </p>
        <p >
          <strong>{{ $t('table_columns.phone') }} : </strong>
          {{ operatorData.telephone_etablissement && operatorData.telephone_etablissement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.web_site') }} : </strong>
          {{ operatorData.web_site && operatorData.web_site }}
        </p>
        <p>
          <strong>{{ $t('table_columns.departement') }} : </strong>
          {{ operatorData.departement && operatorData.departement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.commune') }} : </strong>
          {{ operatorData.commune && operatorData.commune }}
        </p>
        <p>
          <strong>{{ $t('table_columns.district') }} : </strong>
          {{ operatorData.arrondissement && operatorData.arrondissement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.village') }} : </strong>
          {{ operatorData.quartier && operatorData.quartier }}
        </p>
        <p>
          <strong>{{ $t('table_columns.branches') }} : </strong>
          {{ operatorData.succursales && operatorData.succursales }}
        </p>
        <strong style="margin-bottom: 10px; display: inline-block"
          >{{ $t('operator_manage.detail_label_location') }} :
        </strong>
        <div class="map-wrapper" v-if="operatorData.localization && operatorData.localization.lat">
          <MapsComponent :positions="[operatorData.localization.lat,operatorData.localization.lng]" />
        </div>
      </b-card>
       <!--Guide de tourisme-->
      <b-card class="m-0 operator-card" v-else-if="operatorData.secteur_id === 3">
        <h3 class="mb-1">{{ $t('sectorsList.guideTouristique') }} {{ operatorData.nom_promoteur && operatorData.nom_promoteur }} {{ operatorData.prenoms_promoteur && operatorData.prenoms_promoteur }}</h3>
        <div class="operator-images-container" v-if="operatorData.media">
          <div
            v-for="(media, index) in operatorData.media"
            :key="index"
            class="operator-image-wrapper"
            @click="showImgModal(media.original_url, media.name)"
          >
            <img
              :src="media.original_url"
              :alt="media.name"
              class="operator-image"
            />
          </div>
        </div>

        <!--<p>
          <strong>{{ $t('operator_manage.detail_label_ifu') }} : </strong>
          {{ operatorData.ifu }}
        </p>
        <p><strong>RCCM : </strong> {{ operatorData.rccm }}</p>-->

        <p>
          <strong>{{ $t('table_columns.type') }} : </strong>
          {{ operatorData.type && operatorData.type }}      
        </p>
        <p >
          <strong>{{ $t('table_columns.category') }} : </strong>
          {{ operatorData.category && operatorData.category }}      
        </p>
        <p >
          <strong>{{ $t('table_columns.assignment') }} : </strong>
          {{ operatorData.affectation && operatorData.affectation }}      
        </p>
        <p>
          <strong>{{ $t('table_columns.lastname') }} : </strong>
          {{ operatorData.nom_promoteur && operatorData.nom_promoteur }}      
        </p>
        <p >
          <strong>{{ $t('table_columns.firstname') }} : </strong>
          {{ operatorData.prenoms_promoteur && operatorData.prenoms_promoteur }}      
        </p>
        <p>
          <strong>{{ $t('table_columns.gender') }} : </strong>
          {{ operatorData.sexe_promoteur && operatorData.sexe_promoteur }}
        </p>
        <p>
          <strong>{{ $t('table_columns.numero_agrement') }} : </strong>
          {{ operatorData.numero_agrement && operatorData.numero_agrement }}
        </p>
        <p >
          <strong>{{ $t('table_columns.phone') }} : </strong>
          {{ operatorData.telephone_etablissement && operatorData.telephone_promoteur }}
        </p>
        <p>
          <strong>{{ $t('table_columns.web_site') }} : </strong>
          {{ operatorData.web_site && operatorData.web_site }}
        </p>
        <p>
          <strong>{{ $t('table_columns.email') }} : </strong>
          {{ operatorData.email_promoteur && operatorData.email_promoteur }}
        </p>
        <strong style="margin-bottom: 10px; display: inline-block"
          >{{ $t('operator_manage.detail_label_location') }} :
        </strong>
        <div class="map-wrapper" v-if="operatorData.localization && operatorData.localization.lat">
          <MapsComponent :positions="[operatorData.localization.lat,operatorData.localization.lng]" />
        </div>
      </b-card>
      <b-card class="m-0 operator-card" v-else-if="operatorData.secteur_id === 4">
        <h3 class="mb-1">{{ $t('sectorsList.restaurant') }} {{ operatorData.nom_etablissement && operatorData.nom_etablissement }}</h3>
        <div class="operator-images-container" v-if="operatorData.media">
          <div
            v-for="(media, index) in operatorData.media"
            :key="index"
            class="operator-image-wrapper"
            @click="showImgModal(media.original_url, media.name)"
          >
            <img
              :src="media.original_url"
              :alt="media.name"
              class="operator-image"
            />
          </div>
        </div>

        <!--<p>
          <strong>{{ $t('operator_manage.detail_label_ifu') }} : </strong>
          {{ operatorData.ifu }}
        </p>
        <p><strong>RCCM : </strong> {{ operatorData.rccm }}</p>-->
        <p>
          <strong>{{ $t('table_columns.sector') }} : </strong>
          {{ operatorData.secteur.title && operatorData.secteur.title }}
        </p>
        <p>
          <strong>{{ $t('table_columns.type_etablissement') }} : </strong>
          {{ operatorData.secteur.type_etablissement && operatorData.secteur.type_etablissement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.ranking') }} : </strong>
          {{ operatorData.secteur.classement && operatorData.secteur.classement }}
        </p>
        <p >
          <strong>{{ $t('table_columns.company_name') }} : </strong>
          {{ operatorData.nom_etablissement && operatorData.nom_etablissement }}        
        </p>
        <p>
          <strong>{{ $t('table_columns.numero_agrement') }} : </strong>
          {{ operatorData.numero_agrement && operatorData.numero_agrement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.address') }} : </strong>
          {{ operatorData.adresse_etablissement && operatorData.adresse_etablissement }}
        </p>
        <p >
          <strong>{{ $t('table_columns.phone') }} : </strong>
          {{ operatorData.telephone_etablissement && operatorData.telephone_etablissement }}
        </p>
        <p >
          <strong>{{ $t('table_columns.phone') }} : </strong>
          {{ operatorData.email_etablissement && operatorData.email_etablissement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.web_site') }} : </strong>
          {{ operatorData.web_site && operatorData.web_site }}
        </p>
        <p>
          <strong>{{ $t('table_columns.departement') }} : </strong>
          {{ operatorData.departement && operatorData.departement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.commune') }} : </strong>
          {{ operatorData.commune && operatorData.commune }}
        </p>
        <p>
          <strong>{{ $t('table_columns.district') }} : </strong>
          {{ operatorData.arrondissement && operatorData.arrondissement }}
        </p>
        <p>
          <strong>{{ $t('table_columns.village') }} : </strong>
          {{ operatorData.quartier && operatorData.quartier }}
        </p>
        <strong style="margin-bottom: 10px; display: inline-block"
          >{{ $t('operator_manage.detail_label_location') }} :
        </strong>
        <div class="map-wrapper" v-if="operatorData.localization && operatorData.localization.lat">
          <MapsComponent :positions="[{lat:operatorData.localization.lat,lng:operatorData.localization.lng}]" />
        </div>
      </b-card>
      <!--<b-card class="m-0 operator-card" >
        <h3 class="mb-1">{{ $t('table_columns.company') }}</h3>
        <div class="operator-images-container" v-if="operatorData.media">
          <div
            v-for="(media, index) in operatorData.media"
            :key="index"
            class="operator-image-wrapper"
            @click="showImgModal(media.original_url, media.name)"
          >
            <img
              :src="media.original_url"
              :alt="media.name"
              class="operator-image"
            />
          </div>
        </div>

        <!--<p>
          <strong>{{ $t('operator_manage.detail_label_ifu') }} : </strong>
          {{ operatorData.ifu }}
        </p>
        <p><strong>RCCM : </strong> {{ operatorData.rccm }}</p>
        <p v-if="operatorData.nom_etablissement">
          <strong>{{ $t('table_columns.lastname') }} : </strong>
          {{ operatorData.nom_etablissement }}        
        </p>
        <p v-if="operatorData.secteur.title">
          <strong>{{ $t('table_columns.sector') }} : </strong>
          {{ operatorData.secteur.title }}
        </p>

        <p v-if="operatorData.adresse_etablissement">
          <strong>{{ $t('table_columns.address') }} : </strong>
          {{ operatorData.adresse_etablissement }}
        </p>
        <p v-if="operatorData.telephone_etablissement">
          <strong>{{ $t('table_columns.phone') }} : </strong>
          {{ operatorData.telephone_etablissement }}
        </p>
        <p v-if=" operatorData.email_etablissement">
          <strong>{{ $t('table_columns.email') }} : </strong>
          {{ operatorData.email_etablissement }}
        </p>
        <p v-if=" (operatorData.secteur_id === 3 || operatorData.secteur_id === 2) && operatorData.numero_agrement">
          <strong>{{ $t('table_columns.numero_agrement') }} : </strong>
          {{ operatorData.numero_agrement }}
        </p>
        <p v-if="operatorData.web_site">
          <strong>{{ $t('table_columns.web_site') }} : </strong>
          {{ operatorData.web_site }}
        </p>
        <p v-if="operatorData.departement && operatorData.secteur_id != 3">
          <strong>{{ $t('table_columns.departement') }} : </strong>
          {{ operatorData.departement }}
        </p>
        <p v-if="operatorData.commune && operatorData.secteur_id != 3">
          <strong>{{ $t('table_columns.commune') }} : </strong>
          {{ operatorData.commune }}
        </p>
        <p v-if="operatorData.arrondissement && operatorData.secteur_id != 3">
          <strong>{{ $t('table_columns.district') }} : </strong>
          {{ operatorData.arrondissement }}
        </p>
        <p v-if="operatorData.quartier && operatorData.secteur_id != 3">
          <strong>{{ $t('table_columns.village') }} : </strong>
          {{ operatorData.quartier }}
        </p>
        <strong style="margin-bottom: 10px; display: inline-block"
        v-if="operatorData.localization"
          >{{ $t('operator_manage.detail_label_location') }} :
        </strong>
        <div class="map-wrapper" v-if="operatorData.localization">
          <MapsComponent @on_position_fixed="" />
        </div>
      </b-card>-->
      <!--<div class="prom-res-card" v-if="(operatorData.secteur_id != 1 && operatorData.secteur_id != 4 && operatorData.secteur_id != 3 && operatorData.secteur_id != 2 ) || (isAdmin && operatorData.secteur_id === 1)">
        <b-card class="m-0"  >
          <h3 class="mb-1">{{ $t('table_columns.promoter') }}</h3>
          <p v-if="operatorData.nom_promoteur">
            <strong
              >{{ $t('operator_manage.detail_label_lastname_promotor') }} :
            </strong>
            {{ operatorData.nom_promoteur }}
          </p>
          <p v-if="operatorData.prenoms_promoteur">
            <strong
              >{{ $t('operator_manage.detail_label_firstname_promotor') }} :
            </strong>
            {{ operatorData.prenoms_promoteur }}
          </p>
          <p v-if="operatorData.telephone_promoteur">
            <strong
              >{{ $t('operator_manage.detail_label_phone_promotor') }} :
            </strong>
            {{ operatorData.telephone_promoteur }}
          </p>
          <p v-if="operatorData.email_promoteur">
            <strong
              >{{ $t('operator_manage.detail_label_email_promotor') }} :
            </strong>
            {{ operatorData.email_promoteur }}
          </p>
          <p v-if="operatorData.sexe_promoteur">
            <strong>{{ $t('table_columns.gender') }} : </strong>
            {{ operatorData.sexe_promoteur }}
          </p>
        </b-card>
        <b-card class="m-0" v-if="operatorData.nom_representant">
          <h3 class="mb-1">{{ $t('operator_manage.detail_legal_rep') }}</h3>
          <p v-if="operatorData.nom_representant">
            <strong>{{ $t('table_columns.lastname') }} : </strong>
            {{ operatorData.nom_representant }}
          </p>
          <p v-if="operatorData.prenoms_representant">
            <strong>{{ $t('table_columns.firstname') }} : </strong>
            {{ operatorData.prenoms_representant }}
          </p>
          <p v-if="operatorData.sexe_representant">
            <strong>{{ $t('table_columns.gender') }} : </strong>
            {{ operatorData.sexe_representant }}
          </p>
          <p v-if="isAdmin && operatorData.nationalite_representant">
            <strong>{{ $t('table_columns.nationality') }} : </strong>
            {{ operatorData.nationalite_representant }}
          </p>
        </b-card>
      </div>-->
    </div>
    <b-modal
      centered
      id="modal-img-show"
      ref="modal-img-show"
      size="lg"
      title="Visionneuse d'image"
      hide-footer
    >
      <img
        :src="modalImageUrl"
        :alt="modalImageName"
        style="object-fit: cover"
        class="w-100 h-100"
      />
    </b-modal>
  </div>
</template>

<script>
import { BCard, BModal, VBModal } from 'bootstrap-vue'
import etablissementsStroreModule from '@/store/etablissements'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import { mapActions } from 'vuex'
import MapsComponent from '@/components/MapsComponent.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
// store module and vuex utilities

export default {
  components: {
    // BSV
    BCard,
    BModal,
    VBModal,
    MapsComponent,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);
  },
  props: {
    operatorData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      modalImageUrl: '',
      modalImageName: '',
      userDatas:'',
      profile:{},
      isAdmin:false,
    }
  },

  mounted() {
    if (localStorage.getItem("userData")) {
      this.userDatas = localstorageService.getUserData();
      if(userDatas.roles.find(role => role.title === 'Admin'))
      this.isAdmin = true
      console.log("user",this.userDatas)
      console.log("Admin?",this.isAdmin)
    }
    this.getEstablishment()
  },
  methods: {
    ...mapActions('etablissements', {
      action_getEtablissement: 'getEtablissement',
    }),
    getEstablishment() {
      this.action_getEtablissement(this.operatorData.id)
        .then((response) => {
          console.log("response::: ⭕️", response, response.status);
          if (response.status === 200) {
            this.profile = response.data.data;
            console.log("this.profile",this.profile)
          }
        })
        .catch((err) => {
          console.log(err)
        });
    },
    showImgModal(url, name) {
      this.modalImageUrl = url
      this.modalImageName = name
      this.$refs['modal-img-show'].show()
    },
  },
}
</script>
