<template>
  <div class="operator-details">
    <OperatorsDetailsOverviewHebergement v-if="operatorData" :operatorData="operatorData" />
  </div>
</template>

<script>
import { BCard, BModal, VBModal } from 'bootstrap-vue'
import etablissementsStroreModule from '@/store/etablissements'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import { mapActions } from 'vuex'
import MapsComponent from '@/components/MapsComponent.vue'
import OperatorsDetailsOverviewHebergement from '@/components/OperatorsDetailsOverviewHebergement.vue'

// store module and vuex utilities

export default {
  components: {
    // BSV
    BCard,
    BModal,
    VBModal,
    MapsComponent,

    OperatorsDetailsOverviewHebergement,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return {
      requiredStoreModules,
    }
  },
  data() {
    return {
      isFetchingEtsDetails: false,
      operatorData: null,
      operateur_id: null,
      currentStatus: null,
      currentColor: null,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        const { operateur_id } = val.params
        this.operateur_id = operateur_id
        this.loadEtabs(this.operateur_id)
      },
    },
  },
  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() {},
  methods: {
    ...mapActions('etablissements', {
      action_getEtablissement: 'getEtablissement',
    }),

    loadEtabs(operateur_id) {
      this.isFetchingEtsDetails = true
      this.action_getEtablissement(operateur_id)
        .then((res) => {
          if (res.status === 200) {
            this.operatorData = res.data.data
            console.log('🧱🧱🧱 ETS__DETAILS', res.data.data)
            this.isFetchingEtsDetails = false

            if (this.operatorData.isVerified == 'false') {
              this.currentStatus = 'Non traité'
              this.currentColor = 'danger'
            }
            if (this.operatorData.isVerified == 'true') {
              this.currentStatus = 'Validé'
              this.currentColor = 'success'
            }
            if (this.operatorData.isVerified == 'en cours') {
              this.currentStatus = 'Traitement en cours'
              this.currentColor = 'warning'
            }
          }
        })
        .catch((err) => {
          this.isFetchingEtsDetails = false
        })
    },
    showImgModal() {
      this.$refs['modal-img-show'].show()
    },
  },
}
</script>

<style>
.operator-details {
  /* background-color: #fff; */
}
.operator-details-wrapper {
  padding: 1em 3em 4em 3em;
  max-width: 1200px;
  margin: 0 auto;
}
.operator-details-layout {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.operator-card {
  flex: 1;
}
.prom-res-card {
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
}
.operator-images-container {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
.operator-image-wrapper {
  width: 100%;
  max-width: 80px;
  padding: 3px 2px;
  /* border: 1px solid transparent; */
  border-radius: 0.5rem;
  aspect-ratio: 14/9;
}
.operator-image-wrapper:hover {
  background-color: #19925e50;
  cursor: pointer;
}
.operator-image {
  width: 100%;
  max-width: 80px;
  aspect-ratio: 14/9;
  border-radius: 0.5rem;
}
.map-wrapper {
  width: 100%;
  height: 100%;
  max-height: 300px;
}

@media (min-width: 800px) {
  .operator-details-layout {
    flex-direction: row;
  }
}
</style>
